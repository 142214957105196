import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/session";
// Material
import { 
    Avatar, 
    Button, 
    CssBaseline, 
    TextField, 
    Typography,
    Container,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    spinner: {
        color: "#fff"
    }
}));

export default function LoginBox() {
    
    const dispatch = useDispatch();
    const classes = useStyles();
    const [values, setValues] = useState({});
    const { loading, loginError } = useSelector(state => state.session);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} />
                <Typography component="h1" variant="h5">
                    Log in
                </Typography>
                <form className={classes.form} noValidate onSubmit={e => {
                    e.preventDefault();
                    if (!loading) dispatch(login(values));
                }}>
                    <TextField
                        value={values.email || ""}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => setValues({ ...values, email: e.target.value })}
                    />
                    <TextField
                        value={values.password || ""}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => setValues({ ...values, password: e.target.value })}
                    />
                    <Button
                        disabled={!values.email || !values.password}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {loading ? <CircularProgress size={24} className={classes.spinner} /> : "Log In"}
                    </Button>
                </form>
            </div>
            {Boolean(loginError) && <Alert severity="error">{loginError}</Alert>}
        </Container>
    );
}