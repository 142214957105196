import { combineReducers } from "redux";
import initialState from "./initialState";
// Reducer imports.
import session from "./session";
import properties from "./properties";

// Export out the starting state.
export { initialState };

export default combineReducers({
    session,
    properties
});