import { initialState } from ".";
import {
    GET_PROPERTIES_START,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_FAIL,
    CLONE_PROPERTY_START,
    CLONE_PROPERTY_SUCCESS,
    CLONE_PROPERTY_FAIL,
    DELETE_PROPERTY_START,
    DELETE_PROPERTY_SUCCESS,
    DELETE_PROPERTY_FAIL,
    CLEAR_PROPERTY_CLONING_ERROR
} from "../actions/properties";

export default function (state = initialState.properties, action) {
    switch (action.type) {
        case GET_PROPERTIES_START:
            return {
                ...state,
                loading: true
            }

        case GET_PROPERTIES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.properties,
                count: action.count
            }

        case GET_PROPERTIES_FAIL:
            return {
                ...state,
                loading: false
            }

        case CLONE_PROPERTY_START:
            return {
                ...state,
                creating: true,
                cloningError: null
            }

        case CLONE_PROPERTY_SUCCESS:
            return {
                ...state,
                items: [...state.items, action.property],
                count: state.count + 1,
                creating: false
            }

        case CLONE_PROPERTY_FAIL:
            return {
                ...state,
                creating: false,
                cloningError: action.error
            }

        case DELETE_PROPERTY_START:
            return {
                ...state,
                deleting: true
            }

        case DELETE_PROPERTY_SUCCESS:
            return {
                ...state,
                items: state.items.filter(property => property.id !== action.id),
                deleting: false,
                count: state.count - 1
            }

        case DELETE_PROPERTY_FAIL:
            return {
                ...state,
                deleting: false
            }

        case CLEAR_PROPERTY_CLONING_ERROR:
            return {
                ...state,
                cloningError: null
            }

        default:
            return state
    }
}