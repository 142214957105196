import React from 'react';
import { Link } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { logout } from "../../actions/session";
// Material Components
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Material icons
import HomeIcon from '@material-ui/icons/HomeSharp';
import LogoutIcon from '@material-ui/icons/ExitToAppSharp';

const useStyles = makeStyles(theme => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'initial',
        color: 'inherit'
    }
}))

export default function Navigation() {

    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <List>
            <ListItem button>
                <Link to="/dashboard/properties" className={classes.link}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Properties" />
                </Link>
            </ListItem>
            <ListItem button onClick={() => {
                dispatch(logout())
            }}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    )
}