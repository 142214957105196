import axios from "axios";
import moment from "moment";
import { API_ROOT, COOKIE_NAME } from "../constants";
import { setCookie, errorResponse } from "../helpers";

// Authentication.
export const LOGIN_REQUEST_START = "LOGIN_REQUEST_START";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const CURRENT_USER_REQUEST_START = "CURRENT_USER_REQUEST_START";
export const CURRENT_USER_REQUEST_SUCCESS = "CURRENT_USER_REQUEST_SUCCESS";
export const CURRENT_USER_REQUEST_FAIL = "CURRENT_USER_REQUEST_FAIL";

export const LOGOUT = "LOGOUT";
// Misc
export const SET_SESSION_DATA = "SET_SESSION_DATA";

// For authenticating a user.
export function login({ email, password }) {
	return function (dispatch, getState) {

		dispatch({
			type: LOGIN_REQUEST_START,
			credentials: { email, password }
		});

		axios.post(`${API_ROOT}/auth/local`, {
			identifier: email,
			password,
		}).then(({ data }) => {
			// Success!
			dispatch({
				type: LOGIN_REQUEST_SUCCESS,
				user: data.user,
				token: data.jwt
			});
			// Also set a cookie.
			setCookie({
				key: COOKIE_NAME,
				value: data.jwt,
				path: "/",
				expires: moment().add(1, "month")
			});
		}).catch(error => {
			const errorMessage = errorResponse(error);
			dispatch({
				type: LOGIN_REQUEST_FAIL,
				error: errorMessage
			});
		});

	}
}

export function requestCurrentUser(cb = null) {
	return function (dispatch, getState) {
		// Trigger the start.
		dispatch({
			type: CURRENT_USER_REQUEST_START
		});
		// Get the current token.
		const { session: { token } } = getState();
		// If there isn't one, we can exit early.
		if (!token) return dispatch({
			type: CURRENT_USER_REQUEST_FAIL
		});

		axios.get(`${API_ROOT}/users/me`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(res => res.data).then(user => {
			dispatch({
				type: CURRENT_USER_REQUEST_SUCCESS,
				user
            });
            if (cb) cb(user);
		}).catch(error => {
			setCookie({
				key: COOKIE_NAME,
				path: "/",
				value: ""
			});
			dispatch({
				type: CURRENT_USER_REQUEST_FAIL,
				error: errorResponse(error)
			});
		})
	}
}



export function logout() {
	return function (dispatch, getState) {
		setCookie({
			key: COOKIE_NAME,
			path: "/",
			value: ""
		});

		dispatch({
			type: LOGOUT
		});
	}
}

// For setting general session data.
export function setSessionData(data) {
	return {
		type: SET_SESSION_DATA,
		data
	}
};
