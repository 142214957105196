import React from "react";
import { CircularProgress } from '@material-ui/core';

export default function Spinner({ size }) {

    return <div style={{ height: "100%" }}>
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            <CircularProgress size={size} />
        </div>
    </div>
}