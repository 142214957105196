import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getProperties, deleteProperty, clearPropertyCloningError } from "../../actions/properties";
// Material components
import {
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	TablePagination,
	IconButton,
	Paper
} from '@material-ui/core';
// Material Icons
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
// Custom Components
import Spinner from "../Spinner";
import TransitionsModal from "../TransitionsModal";
import PropertyCloner from "./PropertyCloner";
import StaggeredTextField from "../StaggeredTextField";

const tableStyles = makeStyles(theme => ({
	head: {
		fontWeight: theme.typography.fontWeightBold
	},
	title: {
		paddingBottom: theme.spacing(2)
	},
	cell: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	buttonCell: {
		textAlign: "right"
	},
	filter: {
		paddingBottom: theme.spacing(1)
	},
	removeIcon: {
		color: theme.palette.secondary.dark
	}
}));

const paginationStyles = makeStyles(theme => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5)
	}
}));

function addressRenderer(address) {
	const { line1, line2, town, postcode } = address;
	return [line1, line2, town, postcode]
		.filter(item => item)
		.join(", ")
};

function TablePaginationActions(props) {
	const classes = paginationStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
};

export default function Properties() {

	const classes = tableStyles();
	const dispatch = useDispatch();
	const { items, loading, count, deleting } = useSelector(state => state.properties);
	// Uid filter state
	const [uid, setUid] = useState(null);
	// Pagination state
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	// Modal state
	const [cloning, setCloning] = useState(null);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		// Get the list of properties
		const params = {
			_start: page * rowsPerPage,
			_limit: rowsPerPage,
			_sort: "uid:ASC"
		};
		if (Boolean(uid)) params.uid_contains = uid;
		dispatch(getProperties(
			params,
			["development", "development.developer"]
		));
	}, [dispatch, page, rowsPerPage, uid]);

	return (
		<div>
			<Typography className={classes.title} component="h2" variant="h5" color="primary">
				Properties list
            </Typography>
			<StaggeredTextField
				className={classes.filter}
				variant="outlined"
				margin="normal"
				fullWidth
				id="uid"
				label="UID filter"
				name="reference"
				value={uid || ""}
				onChange={uid => {
					setUid(uid);
					setPage(0);
				}}
				onFocus={e => e.target.select()}
			/>
			<TableContainer component={Paper}>
				<Table size="medium">
					<TableHead>
						<TableRow>
							<TableCell className={classes.head}>UID</TableCell>
							<TableCell className={classes.head}>Plot</TableCell>
							<TableCell className={classes.head}>Address</TableCell>
							<TableCell className={classes.head}>Developer</TableCell>
							<TableCell className={classes.head}>Development</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map(property => (
							<TableRow key={property.id}>
								<TableCell className={classes.cell}>{property.uid}</TableCell>
								<TableCell className={classes.cell}>{property.plot}</TableCell>
								<TableCell className={classes.cell}>
									{addressRenderer(property.address || {})}
								</TableCell>
								<TableCell className={classes.cell}>{property.development.developer.name}</TableCell>
								<TableCell className={classes.cell}>{property.development.name}</TableCell>
								<TableCell className={clsx(classes.cell, classes.buttonCell)}>
									<IconButton
										title="clone property"
										onClick={() => setCloning(property.id)}
									>
										<CopyIcon />
									</IconButton>
									<IconButton
										disabled={deleting}
										title="delete property"
										onClick={() => {
											if (window.confirm("Are you sure you want to remove this property?"))
												dispatch(deleteProperty(property.id));
										}}>
										<DeleteIcon className={classes.removeIcon} />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
								// colSpan={3}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>

			{/* Cloning editor */}
			<TransitionsModal
				open={cloning !== null}
				handleClose={() => {
					dispatch(clearPropertyCloningError());
					setCloning(null);
				}}
			>
				<PropertyCloner
					id={cloning}
					onClone={() => setCloning(null)}
				/>
			</TransitionsModal>
		</div>
	)
}
