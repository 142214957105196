import "core-js";
import React, { useEffect } from 'react';
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
// Redux
import { applyMiddleware, createStore, compose } from "redux";
import { Provider, useSelector, useDispatch } from "react-redux";
import thunk from "redux-thunk";
import reducers, { initialState } from "./reducers";
import { requestCurrentUser } from "./actions/session";
// Material
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
// Custom Components
import LoginBox from "./components/LoginBox";
import Dashboard from "./components/Dashboard";
import Spinner from "./components/Spinner";
// Other
import { COOKIE_NAME } from "./constants";
import { getCookies } from "./helpers";

// Init the redux store.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

function App() {

    const dispatch = useDispatch();
    const { user, ready } = useSelector(state => state.session);

    useEffect(() => {
        const cookies = getCookies();
        if (cookies[COOKIE_NAME]) dispatch(requestCurrentUser());
    }, [dispatch]);

    // If not ready return wait
    if (!ready) return <Spinner size={150}/>

    return (
        <div id="app">
            <Switch>
                {!user && <Route
                    path="/login"
                    component={LoginBox}
                />}
                {!!user && <Route
                    path="/dashboard"
                    component={Dashboard}
                />}
                <Route 
                    path="/" 
                    render={router => (
                        <Redirect to={user ? "/dashboard" : "/login"} />
                    )} 
                />
            </Switch>
        </div>
    )
}

render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
