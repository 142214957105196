import React, { useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { cloneProperty } from "../../actions/properties";
// Material
import {
	Grid,
	TextField,
	Button,
	CircularProgress,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(theme => ({
	root: {
		width: "100vw",
		maxWidth: 800,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
	},
	title: {
		textAlign: "center"
	},
	info: {
		textAlign: "center"
	},
	spinner: {
		color: "#fff"
	}
}));

export default function PropertyCloner({ id, onClone }) {

	const classes = useStyles();
	const dispatch = useDispatch();
	const { cloning, property, cloningError } = useSelector(state => ({
		cloning: state.properties.cloning,
		property: state.properties.items.find(property => property.id === id),
		cloningError: state.properties.cloningError
	}));

	const [changes, setChanges] = useState({ address: {} });

	if (!property) return null;

	const values = {
		...property,
		...changes,
		address: {
			...(property.address || {}),
			...changes.address
		},
	}

	return (
		<div className={classes.root}>
			<form className={classes.form} noValidate onSubmit={e => {
				e.preventDefault();
				if (!cloning) dispatch(cloneProperty(id, changes, ["development", "development.developer"], onClone));
			}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography className={classes.title} component="h2" variant="h5" color="primary">
							Cloning {property.uid || property}
                    	</Typography>
						<Typography className={classes.info}>
							<br/>
							Specify any fields you want to change on the new property created.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={values.plot || ""}
							variant="outlined"
							placeholder="Plot"
							label="Plot"
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={e => setChanges({ ...changes, plot: e.target.value })}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							value={values.address.line1 || ""}
							variant="outlined"
							placeholder="Line 1"
							label="Line 1"
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={e => setChanges({
								...changes,
								address: {
									...values.address,
									...changes.address,
									line1: e.target.value
								}
							})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							value={values.address.line2 || ""}
							variant="outlined"
							placeholder="Line 2"
							label="Line 2"
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={e => setChanges({
								...changes,
								address: {
									...values.address,
									...changes.address,
									line2: e.target.value
								}
							})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							value={values.address.town || ""}
							variant="outlined"
							placeholder="Town"
							label="Town"
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={e => setChanges({
								...changes,
								address: {
									...values.address,
									...changes.address,
									town: e.target.value
								}
							})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							value={values.address.postcode || ""}
							variant="outlined"
							placeholder="Postcode"
							label="Postcode"
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={e => setChanges({
								...values,
								address: {
									...values.address,
									...changes.address,
									postcode: e.target.value
								}
							})}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							disabled={!Boolean(changes.plot)}
							fullWidth
							variant="contained"
							color="primary"
							type="submit"
						>
							{cloning ? <CircularProgress size={24} className={classes.spinner} /> : "Submit"}
						</Button>
					</Grid>
					<Grid item xs={12}>
						{Boolean(cloningError) && <Alert severity="error">{cloningError}</Alert>}
					</Grid>
				</Grid>
			</form>
		</div>
	)

}
