import moment from "moment";

export function getCookies() {
	let result = {};
	document.cookie
		.split(";")
		.map(pair => pair.trim().split("="))
		.forEach(([name, value]) => {
			result[name] = value
		});
	return result;
}

export function setCookie({ key, value, path, expires }) {
	const cookie = [
		[key, value || ""],
		path ? ["path", path] : null,
		expires ? ["expires", moment(expires).format("ddd, DD MMM YYYY HH:MM:SS Z")] : null
	]
		.filter(c => c)
		.map(([key, value]) => `${key}=${value}`)
		.join("; ");
	document.cookie = cookie;
}

export function getQuery(removeFromQueryString = []) {
	// Build the object from the query string.
	let result = parseQueryString(window.location.search);
	// If query properties are to be removed, do it here.
	if (removeFromQueryString.length) {
		let allowedValues = { ...result };
		removeFromQueryString.forEach(key => delete allowedValues[key]);
		let newQs = objectToQuery(allowedValues);
		let newUrl = window.location.href.replace(window.location.search, newQs ? "?" + newQs : "");
		// Replace the actual history state to one without the code.
		if (window.history && window.history.replaceState)
			window.history.replaceState({}, "", newUrl);
	}
	return result;
}

export function objectToQuery(object) {
	return Object.entries(object)
		.filter(([, value]) => value !== null)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");
}

export function parseQueryString(qs = "") {
	const result = {}
	qs.replace(/^\?/, "")
		.split("&")
		.map(pair => pair.split("="))
		.forEach(([key, value]) => {
			if (value)
				result[decodeURIComponent(key)] = decodeURIComponent(value);
		});
	return result;
}

export function errorResponse(error) {
	let message;
	if (error.response) {
		message = error.response.data.message;
		if (message instanceof Array) {
			message = message.map(m => m.messages.map(m2 => m2.message).join(" ")).join(" ");
		}
	} else if (error.request) {
		message = "No response from server."
	} else if (error.message) {
		message = error.message;
	} else {
		message = "Unknown error.";
		console.error("Unknown error: ", error);
	}
	return message;
}

export function rid(length = 20) {
	let id = [];
	let charset = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	for (let i = 0; i < length; i++) {
		id.push(charset[Math.round(Math.random() * (charset.length - 1))]);
	}
	return id.join("");
}


// Gets the unique objects from passed array
export function getUnique(arr, comp) {

	// store the comparison values in array
	const unique = arr.map(e => e[comp])

		// store the indexes of the unique objects
		.map((e, i, final) => final.indexOf(e) === i && i)

		// eliminate the false indexes & return unique objects
		.filter((e) => arr[e]).map(e => arr[e]);

	return unique;
}

export function combineObjects() {
	function removeNulls(object) {
		const result = {}
		Object.entries(object).forEach(([key, value]) => {
			if (value) result[key] = value;
		});
		return result;
	}
	return Object.assign({}, ...[...arguments].map(arg => arg ? removeNulls(arg) : {}))
}