import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";

export default function StaggeredTextField({ stagger = 500, onChange = () => { }, value, ...props }) {

	const [inputVal, setInputVal] = useState(value);

	useEffect(() => {
		let to;
		if (inputVal !== value) {
			to = setTimeout(() => {
				onChange(inputVal);
			}, stagger);
		}
		return () => {
			clearTimeout(to);
		}
	}, [inputVal, value, stagger, onChange]);

	return <TextField
		value={inputVal}
		onChange={e => setInputVal(e.target.value)}
		{...props}
	/>
}