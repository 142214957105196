import { getCookies } from "../helpers";
import { COOKIE_NAME } from "../constants";

const cookies = getCookies();

export default {

	session: {
        ready: !Boolean(cookies[COOKIE_NAME]),
        loading: false,
        loginError: null,
        token: cookies[COOKIE_NAME] || null,
        user: null,
    },
    properties: {
        items: [],
        loading: false,
        cloning: false,
        deleting: false,
        cloningError: null,
        count: 0
    }
}