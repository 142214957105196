import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalInner: {
        padding: theme.spacing(2),
        outline: "none",
    },
}));

export default function TransitionsModal({ children, open, handleClose }) {

    const classes = useStyles();

    return (
        <Modal
            className={classes.root}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.modalInner}>
                    {children}
                </div>
            </Fade>
        </Modal>
    )
}