const { createMuiTheme } = require("@material-ui/core");

export default createMuiTheme({
    typography: {
		fontFamily: [
			"Montserrat",
			"sans-serif"
		].join(", "),
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
	}
})