export const MAX_LOG_AGE = 5;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const MEDIA_ROOT = process.env.REACT_APP_MEDIA_ROOT || "";
export const SHOPIFY_ROOT = "https://avandeconnect.com";
export const SHOPIFY_ASSET_ROOT = "//cdn.shopify.com/s/files/1/0023/4317/4199/t/20/assets";
export const COOKIE_NAME = "avande_token";

export const DEFAULT_BRAND = {
	color1: "#29348f",
	color2: "#ffffff"
}
