import axios from "axios";
import { API_ROOT } from "../constants";
import { errorResponse } from "../helpers";

export const GET_PROPERTIES_START = "GET_PROPERTIES_START";
export const GET_PROPERTIES_SUCCESS = "GET_PROPERTIES_SUCCESS";
export const GET_PROPERTIES_FAIL = "GET_PROPERTIES_FAIL";

export const CLONE_PROPERTY_START = "CLONE_PROPERTY_START";
export const CLONE_PROPERTY_SUCCESS = "CLONE_PROPERTY_SUCCESS";
export const CLONE_PROPERTY_FAIL = "CLONE_PROPERTY_FAIL";

export const DELETE_PROPERTY_START = "DELETE_PROPERTY_START";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAIL = "DELETE_PROPERTY_FAIL";

export const CLEAR_PROPERTY_CLONING_ERROR = "CLEAR_PROPERTY_CLONING_ERROR";

// Getting list of all properties
export function getProperties(params = {}, dependencies = []) {
    return function (dispatch, getState) {
        dispatch({
            type: GET_PROPERTIES_START
        });
        // Get the current token.
        const { session: { token } } = getState();

        axios.post(`${API_ROOT}/properties/with-dependencies`, dependencies, {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.data).then(({ properties, count }) => {
            dispatch({
                type: GET_PROPERTIES_SUCCESS,
                properties,
                count
            });
        }).catch(error => {
            console.error(errorResponse(error));
            dispatch({
                type: GET_PROPERTIES_FAIL,
            });
        })
    }
}

// Cloning existing properties
export function cloneProperty(id, changes = {}, dependencies = [], cb) {
    return function (dispatch, getState) {
        dispatch({
            type: CLONE_PROPERTY_START
        });
        // Get the current token
        const { session: { token } } = getState();

        axios.post(`${API_ROOT}/properties/clone/${id}`, {
            changes,
            dependencies
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.data).then(property => {
            dispatch({
                type: CLONE_PROPERTY_SUCCESS,
                property
			});
			if (cb) cb();
        }).catch(error => {
            dispatch({
                type: CLONE_PROPERTY_FAIL,
                error: errorResponse(error)
            });
        });
    }
}

// Deleting a property
export function deleteProperty(id) {
    return function (dispatch, getState) {
        dispatch({
            type: DELETE_PROPERTY_START
        });
        // Get the current token
        const { session: { token } } = getState();

        axios.delete(`${API_ROOT}/properties/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.data).then(property => {
            dispatch({
                type: DELETE_PROPERTY_SUCCESS,
                id
            });
        }).catch(err => {
            console.error(errorResponse(err))
            dispatch({
                type: DELETE_PROPERTY_FAIL,
            });
        })
    }
}

export function clearPropertyCloningError() {
    return {
        type: CLEAR_PROPERTY_CLONING_ERROR
    }
}
